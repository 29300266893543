/**
 * Contact form.
 * @module components/theme/AkteurContact/AkteurContact
 */

import React, { Component } from 'react';
import { Helmet } from '@plone/volto/helpers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
// import { Link } from 'react-router-dom';
import {
  Button,
  Form,
  Message,
  Segment,
  TextArea,
  Grid,
} from 'semantic-ui-react';
import jwtDecode from 'jwt-decode';

import { emailSend, getCurrentUser } from '../../actions';

const messages = defineMessages({
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  title: {
    id: 'Contact form',
    defaultMessage: 'Contact form',
  },
  description: {
    id: 'Fill in this form to contact the site owners.',
    defaultMessage: 'Fill in this form to contact the site owners.',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  nameDescription: {
    id: 'Please enter your full name.',
    defaultMessage: 'Please enter your full name.',
  },
  from: {
    id: 'From',
    defaultMessage: 'From',
  },
  fromDescription: {
    id: 'Please enter your e-mail address',
    defaultMessage: 'Please enter your e-mail address',
  },
  subject: {
    id: 'Subject',
    defaultMessage: 'Subject',
  },
  subjectDescription: {
    id: 'Please, enter a short summary of the message',
    defaultMessage: 'Please, enter a short summary of the message',
  },
  message: {
    id: 'Message',
    defaultMessage: 'Message',
  },
  messageDescription: {
    id: 'Please enter the message you want to send',
    defaultMessage: 'Please enter the message you want to send',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  send: {
    id: 'Send',
    defaultMessage: 'Send',
  },
  messageandsenderrequired: {
    id: 'Both sender address and message are required',
    defaultMessage: 'Both sender address and message are required',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  successMessageSentTitle: {
    id: 'Info',
    defaultMessage: 'Info',
  },
  successMessageSentBody: {
    id:
      'A mail has now been sent to the site administrator regarding your questions and/or comments.',
    defaultMessage:
      'A mail has now been sent to the site administrator regarding your questions and/or comments.',
  },
  feedbackThanks: {
    id: 'Thank you for your feedback',
    defaultMessage: 'Thank you for your feedback',
  },
});

/**
 * Contact form class.
 * @class AkteurContact
 * @param {event} e event
 * @extends Component
 */
class AkteurContact extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    userFullName: PropTypes.string.isRequired,
    userEmail: PropTypes.string.isRequired,
    emailToName: PropTypes.string.isRequired,
    emailToAddress: PropTypes.string.isRequired,
    emailSend: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    userId: PropTypes.string.isRequired,
    getCurrentUser: PropTypes.func.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    error: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs AkteurContact
   */
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      error: null,
      isSuccessful: false,
      message: '',
    };
  }

  /**
   * Component will mount
   * @method componentWillMount
   * @returns {undefined}
   */
  componentWillMount() {
    if (this.props.userEmail === undefined) {
      this.props.getCurrentUser();
    }
  }

  /**
   * On submit handler
   * @method onSubmit
   * @param {Object} data object.
   * @returns {undefined}
   */
  onSubmit() {
    const { message } = this.state;
    const { emailToAddress } = this.props;
    const senderAddress =
      this.props.userEmail ?? 'Anonymer Absender (nicht eingeloggter Nutzer)';
    const name = this.props.userFullName;
    const subject = 'Kontaktaufnahme über vhs-ehrenamtsportal.de';

    if (emailToAddress && senderAddress && message) {
      this.props.emailSend(
        emailToAddress,
        senderAddress,
        message,
        name,
        subject,
      );
      this.setState({
        error: null,
        isSuccessful: true,
      });
    } else {
      this.setState({
        error: {
          message: this.props.intl.formatMessage(
            messages.messageandsenderrequired,
          ),
        },
      });
    }
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { message } = this.state;
    // User is not authenticated
    // if (!this.props.userId) {
    //   return (
    //     <header>
    //       <h2 style={{ textAlign: 'center' }}>
    //         {`Kontakt ${this.props.emailToName}`}
    //       </h2>
    //       <p className="description" style={{ textAlign: 'center' }}>
    //         Wenn Sie diese Person kontaktieren möchten, bitte loggen Sie sich
    //         zunächst <Link to="/login">hier</Link> ein.
    //       </p>
    //     </header>
    //   );
    // }

    // User has no email in his/her profile
    // if (!this.props.userEmail) {
    //   return (
    //     <header>
    //       <h2 style={{ textAlign: 'center' }}>
    //         {`Kontakt ${this.props.emailToName}`}
    //       </h2>
    //       <p className="description" style={{ textAlign: 'center' }}>
    //         Please, fill your email in your portal{' '}
    //         <Link to="/personal-information">profile</Link> in order to be able
    //         to contact this person.
    //       </p>
    //     </header>
    //   );
    // }

    // The success page
    if (this.state.isSuccessful) {
      return (
        <header>
          <h2 style={{ textAlign: 'center' }}>
            {`Kontakt ${this.props.emailToName}`}
          </h2>
          <p style={{ textAlign: 'center' }}>
            <FormattedMessage
              id="Thank you for your feedback"
              defaultMessage="Thank you for your feedback"
            />
          </p>
        </header>
      );
    }

    return (
      <div id="page-contact-form">
        <Helmet title={this.props.intl.formatMessage(messages.title)} />
        <h2 style={{ textAlign: 'center' }}>
          {`Kontakt ${this.props.emailToName}`}
        </h2>
        <Form method="post" onSubmit={this.onSubmit}>
          {this.state.error && (
            <Message
              icon="warning"
              negative
              attached
              header={this.props.intl.formatMessage(messages.error)}
              content={this.state.error.message}
            />
          )}

          <Segment basic>
            <Form.Field inline required>
              <Grid>
                <Grid.Row stretched>
                  <Grid.Column width="4">
                    <div className="wrapper">
                      <label htmlFor="message">IHRE NACHRICHT</label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width="8">
                    <TextArea
                      id="message"
                      name="message"
                      value={message}
                      onChange={this.handleChange}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
          </Segment>
          <Segment basic>
            <Button
              type="submit"
              floated="right"
              color="blue"
              size="big"
              style={{ textTransform: 'uppercase' }}
            >
              Nachricht abschicken
            </Button>
          </Segment>
        </Form>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      loading: state.emailSend.loading,
      loaded: state.emailSend.loaded,
      error: state.emailSend.error,
      userId: state.userSession.token
        ? jwtDecode(state.userSession.token).sub
        : '',
      userFullName: state.currentUser.user.fullname,
      userEmail: state.currentUser.user.email,
    }),
    (dispatch) => bindActionCreators({ emailSend, getCurrentUser }, dispatch),
  ),
)(AkteurContact);
