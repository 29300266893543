import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Button,
  Card,
  Checkbox,
  Container,
  Image,
} from 'semantic-ui-react';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Link } from 'react-router-dom';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import GeoSuggest from '../GeoSuggest/GeoSuggest';
import { searchJobOffers } from '../../actions/matchingTool/jobOfferSearch';
import { suggestJobOffers } from '../../actions/matchingTool/jobOfferSuggest';
// import harstallProfile from '../manage/harstall.jpg';
import distefanoProfile from '../manage/distefano.jpeg';
import { getVocabulary } from '@plone/volto/actions';

const MatchingToolView = (props) => {
  const geoSuggestRef = useRef(null);
  const dispatch = useDispatch();
  const jobOffers = useSelector((state) => state.JobOfferSearch);
  const jobOfferSuggestions = useSelector((state) => state.jobOfferSuggest);
  const jobOfferCategoriesVocab = useSelector(
    (state) =>
      state.vocabularies[
        'ehrenamtsportal.matchingtool.vocabulary.job_offer_categories'
      ],
  );

  const [categories, setCategories] = useState([]);
  const [radius, setRadius] = useState(25);
  const [location, setLocation] = useState([]);
  const [locationName, setLocationName] = useState('');

  useEffect(() => {
    dispatch(
      searchJobOffers({
        job_offer_categories: categories,
        location_radius: radius,
        location_lat: location[0],
        location_lon: location[1],
      }),
    );
    dispatch(
      getVocabulary({
        vocabNameOrURL:
          'ehrenamtsportal.matchingtool.vocabulary.job_offer_categories',
      }),
    );
    dispatch(
      suggestJobOffers({
        job_offer_categories: categories,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, location, radius]);

  const onSelectLocation = (place) => {
    setLocation([place.lat, place.lng]);
    setLocationName(place.suggestionValue);
  };

  const onClearLocation = (place, placename) => {
    setLocation([]);
    setLocationName('');
  };

  const onRadiusChange = (value) => {
    setRadius(value);
  };

  const onCategoryToggle = (event, data) => {
    if (data.checked) {
      setCategories([...categories, data.value]);
    } else {
      setCategories(categories.filter((category) => category !== data.value));
    }
  };
  const resetFilter = () => {
    setCategories([]);
    setLocationName('');
    setLocation([]);
    geoSuggestRef.current.clearInput();
  };

  return (
    <div className="matching-tool-view">
      <div className="matching-tool-header">
        <Container>
          <div className="left">
            <h1>Hier finden Sie Ihr Einsatzfeld!</h1>
            <p>
              Sie möchten sich ehrenamtlich engagieren? Sie möchten interessante
              Menschen kennen lernen und neue Perspektiven einnehmen?
            </p>
            <p>
              Dann unterstützen Sie Organisationen, Vereine und Initiativen bei
              ihrer Arbeit, eröffnen Sie Menschen neue Wege zum Lernen oder
              begleiten Sie sie in ihrem Alltag.
            </p>
            <p>Los geht’s mit der Suche nach Ihrem passenden Engagement!</p>
          </div>
          <div className="right">
            <div>
              <h2>Für Organisationen</h2>
              <p>
                Sie suchen ehrenamtliche Unterstützung? Schalten Sie hier in
                wenigen Minuten Ihr Angebot frei!
              </p>
              <Link
                to={
                  props.token
                    ? `${props?.location?.pathname}/add?type=JobOffer`
                    : '/register?matchingtool'
                }
                className="ui button branded blue"
              >
                Angebot Schalten
              </Link>
            </div>
            <div id="deco-circle" />
          </div>
        </Container>
      </div>
      <Container className="content">
        <div className="offer-container">
          <h2 className="offers-total">
            {jobOffers.total} Angebote{' '}
            {locationName &&
              `in ${locationName.split(',')[0]}, im Umkreis von
          ${radius} Km`}
          </h2>
          <Button
            inverted
            className="brandend blue"
            onClick={() => resetFilter()}
          >
            FILTER ZURÜCKSETZEN
          </Button>
        </div>
        <div></div>
        <Grid>
          <Grid.Column mobile={12} computer={3} className="filter-column">
            <h3>Angebote Filtern</h3>
            <h3>Im Umkreis von</h3>

            <GeoSuggest
              ref={geoSuggestRef}
              lang="DE"
              onSelect={onSelectLocation}
              onClear={onClearLocation}
              placeholder="Einsatzort eingeben"
              country="DE"
            />

            <Slider
              min={0}
              max={100}
              defaultValue={0}
              marks={{ 25: '25km', 50: '50 km', 100: '100 km' }}
              step={null}
              onChange={onRadiusChange}
              disabled={location.length < 2}
            />

            <h3>Einsatzfeld</h3>
            {jobOfferCategoriesVocab?.loaded &&
              jobOfferCategoriesVocab.items.map((category) => (
                <Checkbox
                  onChange={onCategoryToggle}
                  value={category.value}
                  label={category.label}
                />
              ))}
          </Grid.Column>
          <Grid.Column mobile={12} computer={9} className="results">
            <Card.Group itemsPerRow={3} stackable>
              {jobOffers.total > 0 ? (
                jobOffers.items.map((offer) => (
                  <Card href={flattenToAppURL(offer['@id'])}>
                    <Image
                      src={addAppURL(`${offer['@id']}/@@images/image/preview`)}
                    />
                    <Card.Content>
                      <h3>{offer.title}</h3>
                      <p>{offer.job_offer_location_city_name}</p>
                    </Card.Content>
                  </Card>
                ))
              ) : (
                <div className="no-results">
                  <div className="info-circle">i</div>
                  <div>
                    <h2>
                      Leider gibt es für Ihre Suchkriterien keine Ergebnisse
                    </h2>
                    <p>
                      Bitte ändern Sie Ihre Suchkriterien oder setzen Sie Ihre
                      Filtereinstellungen zurück.
                    </p>
                  </div>
                </div>
              )}
            </Card.Group>
            {(categories.length > 0 || location.length > 0) && (
              <>
                {jobOfferSuggestions.items.filter((suggestion) => {
                  return !jobOffers.items.find((originalOffer) => {
                    return suggestion['@id'] === originalOffer['@id'];
                  });
                }).length > 0 && (
                  <h2>Diese Angebote könnten Sie interessieren</h2>
                )}
                <Card.Group itemsPerRow={3} stackable>
                  {jobOfferSuggestions.items.map(
                    (offer) =>
                      !jobOffers.items.some(
                        (e) => e['@id'] === offer['@id'],
                      ) && (
                        <>
                          <Card href={flattenToAppURL(offer['@id'])}>
                            <Image
                              src={addAppURL(
                                `${offer['@id']}/@@images/image/preview`,
                              )}
                            />
                            <Card.Content>
                              <h3>{offer.title}</h3>
                              <p>{offer.job_offer_location_city_name}</p>
                            </Card.Content>
                          </Card>
                        </>
                      ),
                  )}
                </Card.Group>
              </>
            )}
          </Grid.Column>
          <div className="contact-box">
            <div>
              <p>
                <b>Sie haben Fragen?</b> <br />
                Kontaktieren Sie mich!
              </p>
              <p>
                <b>Aurora Distefano</b>
                <br />
                Tel.: 0228-97569440
                <br />
                <a href="mailto:distefano@dvv-vhs.de">distefano@dvv-vhs.de</a>
              </p>
            </div>
            <div className="profile-image-wrapper">
              <img src={distefanoProfile} alt="Profilbild Aurora Distefano" />
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

export default MatchingToolView;
